var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',[_c('div',{staticClass:"f-list pd:lg"},[_c('div',{staticClass:"f-list-right"},[_c('a',{on:{"click":function($event){return _vm.$store.dispatch('ui/setModal', { name: '', visible: false })}}},[_c('i',{staticClass:"material-icons"},[_vm._v(" close ")])])])]),_c('div',{staticClass:"pd:xl",staticStyle:{"padding-top":"0","text-align":"center"}},[_c('form',{staticClass:"app-form"},[_c('label',{directives:[{name:"t",rawName:"v-t",value:('manage_subscription'),expression:"'manage_subscription'"}]}),_c('div',{staticClass:"pd:md",staticStyle:{"padding-left":"0","padding-right":"0","padding-bottom":"0"}},[_c('div',{staticClass:"text-sub",staticStyle:{"color":"inherit"}},[((['A', 'S']).includes(_vm.$store.state.auth.status))?_c('div',[(_vm.packageHasMultiple || !(_vm.charging.price && _vm.charging.period))?_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'manage_subscription_subscribed', args: {
								brand_name: _vm.$t('brand_name')
							} }),expression:"{ path: 'manage_subscription_subscribed', args: {\n\t\t\t\t\t\t\t\tbrand_name: $t('brand_name')\n\t\t\t\t\t\t\t} }"}]}):_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'manage_subscription_subscribed_with_package', args: {
								brand_name: _vm.$t('brand_name'),
								price: _vm.charging.price,
								period: _vm.charging.period
							} }),expression:"{ path: 'manage_subscription_subscribed_with_package', args: {\n\t\t\t\t\t\t\t\tbrand_name: $t('brand_name'),\n\t\t\t\t\t\t\t\tprice: charging.price,\n\t\t\t\t\t\t\t\tperiod: charging.period\n\t\t\t\t\t\t\t} }"}]}),(!_vm.isTelcoWapUnsubscribeSupported)?_c('div',{staticClass:"pd:md",staticStyle:{"padding-left":"0","padding-right":"0","padding-bottom":"0"}},[_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'manage_subscription_subscribed_p2_wap', args: {
									keyword: _vm.sms.unsubscribe.keyword,
									shortcode: _vm.sms.unsubscribe.shortcode
							} }),expression:"{ path: 'manage_subscription_subscribed_p2_wap', args: {\n\t\t\t\t\t\t\t\t\tkeyword: sms.unsubscribe.keyword,\n\t\t\t\t\t\t\t\t\tshortcode: sms.unsubscribe.shortcode\n\t\t\t\t\t\t\t} }"}]})]):_vm._e()]):_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'manage_subscription_not_subscribed', args: { brand_name: _vm.$t('brand_name') } }),expression:"{ path: 'manage_subscription_not_subscribed', args: { brand_name: $t('brand_name') } }"}]}),(!_vm.packageHasMultiple && _vm.charging.price && _vm.charging.period)?_c('div',{directives:[{name:"t",rawName:"v-t",value:({ path: 'manage_subscription_not_subscribed_description', args: {
								price: _vm.charging.price,
								period: _vm.charging.period
							} }),expression:"{ path: 'manage_subscription_not_subscribed_description', args: {\n\t\t\t\t\t\t\t\tprice: charging.price,\n\t\t\t\t\t\t\t\tperiod: charging.period\n\t\t\t\t\t\t\t} }"}]}):_vm._e()])])])]),_c('div',{staticClass:"separator",attrs:{"data-size":"md"}}),(_vm.isTelcoWapUnsubscribeSupported)?_c('label',{staticClass:"app-button pd_horz:md text-trim",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){_vm.isLoading = true; _vm.manageSubscription()}}},[((['A', 'S']).includes(_vm.$store.state.auth.status))?_c('span',{directives:[{name:"t",rawName:"v-t",value:('unsubscribe'),expression:"'unsubscribe'"}]}):_c('span',{directives:[{name:"t",rawName:"v-t",value:('subscribe'),expression:"'subscribe'"}]})]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }