<template>
	<div
		@click="closeModalViaBackground($event)"
		@keydown.esc="$store.dispatch('ui/setModal', { name: '', visible: false })"
		class="app-modal pd:xl"
		tabindex="-1">
		<transition name="modal">
			<div v-if="modalIsOpen" class="app-modal-main" :style="{ width: modalWidth ? modalWidth : 'auto' }">
				<component :is="modalCurrent"></component>
			</div>
		</transition>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default {
	methods: {
		closeModalViaBackground (event) {
			if (event.target.classList.contains('app-modal')) {
				this.$store.dispatch('ui/setModal', { name: '', visible: false })
			}
		}
	},
	computed: {
		...mapState('ui', {
			modalIsOpen: state => state.modal.isOpen,
			modalCurrent: state => state.modal.current,
			modalWidth: state => state.modal.width
		})
	},
	watch: {
		'modalIsOpen': { // eslint-disable-line
			handler (state) {
				if (state) {
					document.documentElement.style.top = -(window.pageYOffset || document.documentElement.scrollTop) + 'px'
					document.documentElement.classList.add('modal-open')
					this.$nextTick(_ => document.querySelector('.app-modal').focus())
				} else {
					document.documentElement.classList.remove('modal-open')
					window.scrollTo(0, -parseInt(document.documentElement.style.top, 10))
				}
			},
			immediate: true
		},
		'modalCurrent': { // eslint-disable-line
			handler (componentName) {
				if (!componentName) return

				Vue.component(componentName, _ => import('@/components/' + componentName))
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
// basic theming
.app-modal {
	background-color: rgba($color-background, .7);
	transition-property: background-color;
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-duration: 300ms;

	.app-modal-main {
		border-radius: .625em;
		box-shadow: .25em .25em .735em rgba(0, 0, 0, .16);
		overflow: hidden;
		background-color: $color-background-invert;
		background-image: $color-background-gradient-invert;
		color: $color-foreground-invert;
	}

	.modal-enter, .modal-leave-to {
		transform: scale(.97);
		transition-property: transform;
		transform-origin: center;
		transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

		& > * {
			opacity: 0;
			transition-property: opacity;
			transform-origin: center;
			transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
		}
	}

	.modal-enter-active {
		transition-delay: 150ms;
		transition-duration: 600ms;

		& > * {
			transition-delay: 300ms;
			transition-duration: 600ms;
		}
	}

	.modal-leave-active {
		opacity: 0;
		transform: scale(.97);
		transition-property: transform, opacity;
		transition-delay: 0;
		transition-duration: 300ms;

		& > * {
			opacity: 0;
			transition-property: opacity;
			transition-delay: 0;
			transition-duration: 300ms;
		}
	}

	html:not(.modal-open) & {
		background-color: transparent;
	}
}

// structure
.app-modal {
	display: flex;
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	// flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 1;

	> * {
		position: relative;
		margin: auto;
		width: 25em;
		max-width: 100%;
	}

	html:not(.modal-open) & {
		pointer-events: none !important;
	}
}
</style>

<style lang="scss">
html.modal-open {
	position: fixed;
	overflow: hidden;
}
</style>
