<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<form @submit.prevent="formProfileUpdateName" class="app-form">
					<label v-t="'username'"></label>
					<input
						@keyup="validateOnTyping"
						v-model="user.name"
						class="text-sub pd:md"
						type="text"
						:placeholder="$t('profile_edit_name_placeholder')" autofocus required>

					<div
						v-if="!form.isPristine && message.error"
						class="error">
						{{ message.error }}
					</div>

					<button id="form-profile-update-name-submit" type="submit" style="display: none !important"></button>
				</form>

				<div class="separator" data-size="md"></div>

				<label
					v-t="'save'"
					for="form-profile-update-name-submit"
					class="app-button pd_horz:md text-trim"
					:disabled="isLoading">
				</label>
			</div>
		</section>
	</div>
</template>

<script>
import { debounce } from 'lodash'

export default {
	name: 'modal-profile-update-name',
	data () {
		return {
			isLoading: false,
			form: {
				isPristine: true
			},
			user: {
				name: this.$store.getters['users/auth'].name
			},
			message: {
				error: ''
			}
		}
	},
	methods: {
		validateOnTyping: debounce(function () {
			this.message.error = ''
			this.user.name = this.user.name.replace(/\s\s+/g, ' ')

			// [VALIDATE] format: match required alphanumeric, option to have trimmed space, within 6-15 chars
			if (!(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\s]{6,15})$/).test(this.user.name)) {
				this.message.error = this.$i18n.t('error_username_invalid_format')
			}
		}, 300),
		async formProfileUpdateName () {
			this.form.isPristine = false
			this.user.name = this.user.name.replace(/\s\s+/g, ' ')

			// [VALIDATE] format: match required alphanumeric, option to have trimmed space, within 6-15 chars
			if (!(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\s]{6,15})$/).test(this.user.name)) {
				this.message.error = this.$i18n.t('error_username_invalid_format')

				return
			}

			// [VALIDATE] same name
			if (this.user.name === this.$store.getters['users/auth'].name) {
				this.closeModal()

				return
			}

			// [API]
			this.isLoading = true

			const model = 'user'
			const payload = { set_profile_name: [{ args: [{ name: this.user.name }] }] }

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: [{ [model]: [payload] }]
			})

			const result = responses[model].set_profile_name[0][0]

			switch (result.code) {
				case 2001: {
					await this.$store.dispatch('users/update', { uids: this.$store.state.auth.uid })
					this.isLoading = false

					this.closeModal()
					break
				}

				// unfinished: wait for api error code for taken
				case 'E1001': {
					this.message.error = this.$i18n.t('error_username_taken')
					this.isLoading = false
					break
				}

				default: {
					this.message.error = this.$i18n.t('error_general')
					this.isLoading = false
				}
			}
		},
		closeModal () {
			// handle redirect
			if (this.$route.path && this.$store.state.ui.modal.redirect !== this.$route.path) {
				this.$router.push({ path: this.$store.state.ui.modal.redirect })
			}

			setTimeout(_ => this.$store.dispatch('ui/setMenu', false))

			this.$store.dispatch('ui/setModal', { name: '', visible: false })
		}
	},
	async created () {
		this.$store.dispatch('ui/setModalWidth', { width: '25em' })
	},
	mounted () {
		document.querySelector('[autofocus]').focus()
	}
}
</script>

<style lang="scss" scoped>
.error {
	@extend %text-sub;
	color: $color-accent-pink;
	text-align: left;

	&:before {
		content: '\2731\0020'
	}
}
</style>
