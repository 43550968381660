<template>
	<div>
		<the-modal/>
		<the-sidebar/>
		<div class="app-content">
			<the-header/>
			<slot></slot><!-- <router-view/> will be rendered here -->
			<the-fab-scroll-top/>
		</div>
		<the-footer v-if="$route.meta.ui === undefined || $route.meta.ui.footer"/>
	</div>
</template>

<script>
import TheHeader from '@/components/the-header'
import TheSidebar from '@/components/the-sidebar'
import TheModal from '@/components/the-modal'
import TheFooter from '@/components/the-footer'
import TheFabScrollTop from '@/components/the-fab-scroll-top'

export default {
	name: 'layout-default',
	components: {
		TheHeader,
		TheSidebar,
		TheModal,
		TheFooter,
		TheFabScrollTop
	}
}
</script>

<style lang="scss" scoped>
.app-sidebar {
	position: fixed;
	overflow-y: auto;
	height: 100%;
	width: $sidebar-width;
	transition: transform .3s;
}

.app-sidebar.app-sidebar-open {
	transform: translateX(0);
}

.app-sidebar ~ * {
	position: relative;
	transform: translateX(0);
	transition: transform .3s;
}

.app-content {
	display: inherit; flex-direction: inherit; flex-grow: inherit;
	background-color: $color-background-secondary;
}
</style>

<style lang="scss">
html {
	&:not([dir="rtl"]) {
		.app-sidebar {
			transform: translateX(-$sidebar-width);

			&.app-sidebar-open ~ * {
				transform: translateX($sidebar-width);
			}
		}
	}

	&[dir="rtl"] {
		.app-sidebar {
			transform: translateX($sidebar-width);

			&.app-sidebar-open ~ * {
				transform: translateX(-$sidebar-width);
			}
		}
	}
}
</style>
