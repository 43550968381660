<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div v-if="service" class="pd:xl" style="padding-top: 0; text-align: center">
				<div class="logo-text">
					<img :src="require(`@/assets/images/logo-${ appShortName || 'fg' }-text.png`)" :alt="`thumbnail of ${$t('brand_name')}'s logo'`">
				</div>

				<div class="thumbnail">
					<img src="@/assets/images/thumbnail-game_on.png" :alt="`thumbnail of multiple characters from different games`">
				</div>

				<div class="app-heading pd:md" style="padding-top: 0">
					<div v-t="'cta_subscribe'" class="app-title"></div>
				</div>

				<div
					v-if="Object.keys(pricing).length > 0"
					class="pd:md" style="padding-left: 0; padding-right: 0; padding-top: 0">
					<div>
					</div>

					<div>
						9 Baht / day	
					</div>

					<div
						v-if="this.contactMailIsShown"
						v-html="$t('contact_description', { html_mail: `<a href='mailto: ${this.contactMail}' class='app-link'>${this.contactMail}</a>` })">
					</div>
				</div>

				<div
					v-for="(p, packageName, index) in service.packages"
					:key="`package-${index}`"
					class="pd:sm" style="padding-left: 0; padding-right: 0; padding-top: 0">
					<a
						:href="p.subscribeUrl"
						v-t="Object.keys(service.packages).length > 1 ? { path: 'pricing_occurrence', args: {
							price: pricing[packageName].price,
							occurrence: pricing[packageName].occurrence
						} } : 'subscribe'"
						class="app-button pd_horz:md text-trim" :data-css-style="index % 2 ? 'invert' : ''">
					</a>
				</div>

				<div>
					<div v-t="'cta_login_p1'"></div>
					<a
						@click="$store.dispatch('ui/setModal', { name: 'modal-login' })"
						v-t="'cta_login_p2'"
						class="app-link">
					</a>
				</div>

				<div
					v-html="$t('confirmation_tnc', { html: `<a href='/terms' onclick='event.preventDefault();app.__vue__.$router.push({ path: this.attributes.href.value })' class='app-link'>${$t('tnc')}</a>` })"
					class="pd:md" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
				</div>
				<div
					v-if="service.trialPackage.periodInDay > 0 && service.trialPackage.type === 'new'"
					v-t="'subscription_trial_description_extra_new_user'">
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'modal-advice-of-charge',
	data () {
		return {
			service: '',
			pricing: {},
			appShortName: process.env.VUE_APP_SHORTNAME,
			contactMail: process.env.VUE_APP_CONTACT_MAIL,
			contactMailIsShown: JSON.parse(process.env.VUE_APP_CONTACT_MAIL_IS_SHOWN || null)
		}
	},
	methods: {
		async goLogin () {
			if (process.env.VUE_APP_URL_PARAMS_PORTAL_LOGIN) {
				this.$store.dispatch('ui/setModal', { name: '', visible: false })

				window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_LOGIN}`

				return
			}

			this.$store.dispatch('ui/setModal', { name: 'modal-login' })
		},

		async init () {
			this.service = this.$store.state.operator

			Object.keys(this.service.packages).forEach(packageName => {
				this.pricing[packageName] = {
					price: this.service.packages[packageName].price
				}

				switch (packageName) {
					case 'd': {
						this.pricing[packageName].occurrence = this.$tc('occurrence_day')
						break
					}
					case 'w': {
						this.pricing[packageName].occurrence = this.$tc('occurrence_week')
						break
					}
					case 'm': {
						this.pricing[packageName].occurrence = this.$tc('occurrence_month')
						break
					}
				}
			})
		}
	},
	async created () {
		this.$store.dispatch('ui/setModalWidth', { width: '30em' })

		await this.init()
	},
	watch: {
		$route (to, from) {
			this.$store.dispatch('ui/setModal', { name: '', visible: false }) // hack to close modal when navigate to terms page
		}
	}
}
</script>

<style lang="scss" scoped>
/deep/ section {
	.logo-text {
		margin: 0 auto 2em;
		width: 100%;
		max-width: 8em;

		img {
			width: 100%;
			height: auto;
		}
	}

	.thumbnail {
		margin: 2em auto 0;
		width: 100%;
		max-width: 25em;

		img {
			width: 100%;
			height: auto;
		}
	}

	.app-heading {
		.app-title {
			color: $color-accent;
		}
	}
}
</style>
