<template>
	<main>
		<section>
			<div v-if="isLoading" class="preloader"></div>

			<div v-else>
				<div v-if="Object.keys(game).length > 0">
					<router-link
						tag="a"
						class="game-preview-image"
						:key="game.uid"
						:to="'/play/' + game.uid">
						<div class="img_cover">
							<img
								:src="$util.bindUrlCdn(game.banner_images)"
								:alt="`thumbnail of game named ${game.name}`">
						</div>
					</router-link>

					<div class="app-heading pd:md">
						<b>{{ game.name }}</b>
					</div>
				</div>

				<div v-else>
					<div class="pd:xl" style="text-align: center">
						Game not Found
					</div>
				</div>
			</div>
		</section>

		<div v-if="gamesRecommended.length > 0" class="separator"></div>

		<section v-if="gamesRecommended.length > 0">
			<div class="pd:md">
				<div style="text-align: center">Recommended</div>
			</div>
			<component-games-list-slider :games="gamesRecommended"/>
		</section>

		<div class="separator"></div>

		<section>
			<div v-if="isLoading" class="preloader"></div>

			<div v-else>
				<div v-if="Object.keys(game).length > 0">
					<div class="pd:md">
						<div>rate it</div>
						<div>share it</div>
						<div>review it</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import ComponentGamesListSlider from '@/components/component-games-list-slider'

export default {
	name: 'game_profile',
	inject: ['$util'],
	components: {
		ComponentGamesListSlider
	},
	data () {
		return {
			isLoading: false,
			game: {},
			gamesRecommended: []
		}
	},
	methods: {
		async getGameProfile (gid) {
			this.isLoading = true

			const payloadGames = [{
				game: [
					{ get_content_banner: [{ args: [{ limit: 1 }] }] }
				]
				// add games recommended here
			}]

			const responses = await this.$store.dispatch('api/fetch', {
				method: 'POST',
				payload: payloadGames
			})

			const result = responses.game['get_content_banner'][0][0]

			this.game = result.data[0]

			// this.gamesRecommended.push(result.data[0]) // fake result

			this.isLoading = false
		}
	},
	created () {
		this.getGameProfile(this.$route.params.gid)
	}
}
</script>

<style lang="scss" scoped>
section {
	background-color: $color-background;
	color: $color-foreground;
}

.game-preview-image {
	.img_cover {
		padding-bottom: 63.8%;
		z-index: 0;

		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-color: rgba(black, .5);
			z-index: 1;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-size: 5em;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url('@/../../assets/images/icon-play-invert.png');
			opacity: .6;
			z-index: 1;
		}
	}
}
</style>
