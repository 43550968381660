<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<form @submit.prevent="formLogin" class="app-form">
					<div class="modal-heading">{{ $t('login_title', { brand_name: $t('brand_name') }) }}</div>

					<div class="separator" data-size="md"></div>

					<label v-t="'phone_number'"></label>
					<input v-model="user.msisdn" class="text-sub pd:md" type="tel" :placeholder="`eg. ${placeholderMsisdn}`" autofocus required>

					<label v-t="'password'" class="pd:md"></label>
					<input v-model="user.password" class="text-sub pd:md" type="password" :placeholder="$t('login_password_placeholder')" required>

					<div
						v-if="!form.isPristine && message.error"
						class="error"
						style="margin-bottom: 1em">
						{{ message.error }}
					</div>

					<button id="form-login-submit" type="submit" style="display: none !important"></button>

					<a v-t="'forgot_password_title'" :href="urlForgotPassword" class="app-link"></a>
				</form>

				<div class="separator" data-size="md"></div>

				<label
					v-t="'log_in'"
					for="form-login-submit"
					class="app-button pd_horz:md text-trim"
					:disabled="isAuthenticating">
				</label>

				<div class="separator" data-size="md"></div>

				<div class="text-sub" style="color: inherit">
					<div v-t="'cta_join_p1'"></div>
					<a v-t="'cta_join_p2'" :href="urlSubscribe" class="app-link"></a>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'modal-login',
	data () {
		return {
			isAuthenticating: false,
			user: {
				msisdn: '',
				password: ''
			},
			form: {
				isPristine: true
			},
			message: {
				error: ''
			},
			urlSubscribe: `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_SUB}`,
			urlForgotPassword: `${process.env.VUE_APP_URL_PORTAL}/forget`,
			placeholderMsisdn: process.env.VUE_APP_PLACEHOLDER_MSISDN
		}
	},
	methods: {
		async formLogin () {
			this.isAuthenticating = true
			this.form.isPristine = false
			this.message.error = ''

			const result = await this.$store.dispatch('auth/login', {
				msisdn: this.user.msisdn,
				password: this.user.password
			})

			this.isAuthenticating = false

			switch (result.code) {
				case 2001: {
					// handle redirect
					if (this.$route.path && this.$store.state.ui.modal.redirect !== this.$route.path) {
						this.$router.push({ path: this.$store.state.ui.modal.redirect })
					}

					setTimeout(_ => this.$store.dispatch('ui/setMenu', false))

					this.$store.dispatch('ui/setModal', { name: '', visible: false })
					break
				}

				case 'E3102': {
					this.message.error = this.$i18n.t('error_login_invalid')
					break
				}

				case 'E4008': {
					this.message.error = this.$i18n.t('error_general')
					this.$store.dispatch('auth/_reinit')
					break
				}

				default: {
					this.message.error = this.$i18n.t('error_general')
					console.warn(result)
				}
			}
		}
	},
	beforeCreate () {
		if (process.env.VUE_APP_URL_PARAMS_PORTAL_LOGIN) {
			this.$store.dispatch('ui/setModal', { name: '', visible: false })

			window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_LOGIN}`
		}
	},
	mounted () {
		document.querySelector('[autofocus]').focus()
	},
	watch: {
		$route () {
			// this.$store.dispatch('ui/setModal', { name: '', visible: false })
		}
	}
}
</script>

<style lang="scss" scoped>
hr {
	color: transparent;
	border-color: transparent;
}

.error {
	@extend %text-sub;
	color: $color-accent-pink;
	text-align: left;

	&:before {
		content: '\2731\0020'
	}
}
</style>
