<template>
	<a
		v-if="$route.meta.ui && $route.meta.ui['fab-scroll-top'] !== undefined ? $route.meta.ui['fab-scroll-top'] : true"
		@click="$util.scrollToTop()"
		:class="['app-fab app-fab-scroll-top pd:sm', $store.state.ui.fab.scrollTop ? '' : 'hide']">
		<div>
			<i class="material-icons"> keyboard_arrow_up </i>
		</div>
	</a>
</template>

<script>
import { throttle } from 'lodash'

export default {
	name: 'the-fab-scroll-top',
	inject: ['$util'],
	methods: {
		toggleFabScrollTopOnScroll: throttle(function () {
			this.$store.dispatch('ui/toggleFab', {
				fab: 'scrollTop',
				visible: window.scrollY > document.documentElement.clientHeight / 2
			})
		}, 300, { leading: true, trailing: true })
	},
	created () {
		window.addEventListener('scroll', this.toggleFabScrollTopOnScroll)
	},
	destroyed () {
		window.removeEventListener('scroll', this.toggleFabScrollTopOnScroll)
	}
}
</script>

<style lang="scss" scoped>
.app-fab {
	position: fixed;
	display: flex;
	opacity: .5;
	bottom: 3%;
	width: $pd * 3;
	height: $pd * 3;
	transition: all .3s;
	background-image: $color-background-gradient-invert;
	color: $color-accent-orange !important;
	border-radius: 50%;
	z-index: 1;

	&:hover {
		opacity: 1;
	}

	&.hide {
		opacity: 0 !important;
		pointer-events: none !important;
	}

	> * {
		margin: auto
	}
}
</style>

<style lang="scss">
html {
	&:not([dir="rtl"]) {
		.app-fab {
			right: 3%;
		}
	}

	&[dir="rtl"] {
		.app-fab {
			left: 3%;
		}
	}
}
</style>
