<template>
	<header class="f-list" data-css-gutter="none">
		<a @click="$store.dispatch('ui/setMenu', true)">
			<i class="material-icons" data-css-size="lg"><img :src="require(`@/assets/images/logo-${ appShortName || 'fg' }-image.png`)" alt="menu"></i>
		</a>
		<div :style="selectReplicateStyle" class="app-select-replicate"></div>
		<div style="flex-grow: 1; text-align: center">
			<router-link to="/" tag="a" class="title" data-css-size="lg">
				<img :src="require(`@/assets/images/logo-${ appShortName || 'fg' }-text.png`)" :alt="`thumbnail of ${$t('brand_name')}'s logo'`">
			</router-link>
		</div>
		<label
			class="app-select"
			:data-text="localeDefault.substring(0, 2)">
			<select
				@change="$store.dispatch('i18n/setLocale', { locale: $event.target.value })">
				<option
					:key="locale"
					:value="locale"
					:selected="locale == localeDefault"
					v-for="locale in localeSupported">
					{{ locale.substring(0, 2) }}
				</option>
			</select>
		</label>

		<!--a><i class="material-icons">search</i></a-->
	</header>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data () {
		return {
			selectReplicateStyle: {},
			isFeatureEnabledTranslations: JSON.parse(process.env.VUE_APP_FEATURE_TRANSLATIONS || null),
			appShortName: process.env.VUE_APP_SHORTNAME
		}
	},
	methods: {
		selectReplicateStyleCalculate () {
			this.selectReplicateStyle = {
				height: document.querySelector('header .app-select').offsetHeight + 'px',
				width: (document.querySelector('header .app-select').offsetWidth - 5 * 14) + 'px',
				padding: 0,
				flexShrink: 1
			}

			if (!this.isFeatureEnabledTranslations) {
				document.querySelector('header .app-select').setAttribute('style', 'opacity: 0 !important; pointer-events: none !important')
			}
		}
	},
	computed: {
		...mapState('i18n', {
			localeSupported: 'supported',
			localeDefault: 'default'
		})
	},
	mounted () {
		this.selectReplicateStyleCalculate()
	}
}
</script>

<style lang="scss" scoped>
header {
	background-color: $color-background-invert;
	color: $color-foreground-invert;
	height: 4.5em;

	> * {
		padding: 1.75em 1em;
		line-height: 1;
	}

	.title {
		$scale: 1.3;

		display: inline-block;
		margin: -(($scale - 1em) / 2 / $scale);
		width: 100%;
		vertical-align: middle;
		font-size: $scale * 1em;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
		text-overflow: ellipsis;
		white-space: nowrap;
		// overflow: hidden; // temporaily off for image title
		cursor: pointer;

		img {
			display: block;
			margin: auto;
			height: 1em;
			width: auto;
		}

		&[data-css-size="lg"] {
			$scale: 2;

			margin: -(($scale - 1em) / 2 / $scale);
			font-size: $scale * 1em;
		}
	}
}

.app-select {
	font-weight: 600;
	text-transform: uppercase;
}
</style>

<style lang="scss">
html {
	&:not([dir="rtl"]) {
		header {
			> *:first-child {
				padding-left: 1.75em;
			}

			> *:last-child {
				padding-right: 1.75em
			}
		}
	}

	&[dir="rtl"] {
		header {
			> *:first-child {
				padding-right: 1.75em;
			}
			> *:last-child {
				padding-left: 1.75em
			}
		}
	}
}
</style>
