<template>
	<footer>
		<section>
			<article v-if="contactMailIsShown">
				<div class="app-heading pd:md">
					<div class="app-title" v-t="'contact_us'"></div>
				</div>

				<div class="links">
					<div class="pd:md">
						<a class="app-link text-sub" :href="`mailto: ${contactMail} `">
							{{ contactMail }}
						</a>
					</div>
				</div>
			</article>

			<article>
				<div class="app-heading pd:md">
					<div class="app-title" v-t="'more_info'"></div>
				</div>

				<div class="links">
					<div class="pd:md">
						<router-link
							tag="a"
							class="app-link text-sub"
							:to="'/faq'"
							v-t="'faq'">
						</router-link>
					</div>
					<div class="pd:md">
						<router-link
							tag="a"
							class="app-link text-sub"
							:to="'/terms'"
							v-t="'terms'">
						</router-link>
					</div>
					<div class="pd:md">
						<router-link
							tag="a"
							class="app-link text-sub"
							:to="'/privacy_policy'"
							v-t="'privacy_policy'">
						</router-link>
					</div>
				</div>
			</article>
		</section>

		<section class="app-copyright pd:md">
			<div class="text-sub">
				<span
					v-if="this.brandPoweredBy"
					v-t="{ path: 'notice_copyright_declaration_with_powered_by', args: {
						year: getYearCopyright,
						powered_by: this.brandPoweredBy
					} }">
				</span>
				<span
					v-else
					v-t="{ path: 'notice_copyright_declaration', args: { year: getYearCopyright } }">
				</span>
			</div>
		</section>
	</footer>
</template>

<script>
export default {
	name: 'TheFooter',
	data () {
		return {
			brandPoweredBy: process.env.VUE_APP_POWERED_BY,
			contactMail: process.env.VUE_APP_CONTACT_MAIL,
			contactMailIsShown: JSON.parse(process.env.VUE_APP_CONTACT_MAIL_IS_SHOWN || null)
		}
	},
	computed: {
		getYearCopyright () {
			const year = new Date().getFullYear()

			return year > process.env.VUE_APP_YEAR_LAUNCH ? `${process.env.VUE_APP_YEAR_LAUNCH} - ${year}` : year
		}
	}
}
</script>

<style lang="scss" scoped>
footer {
	border-top: .5em solid $color-accent-pink;

	.links {
		> * {
			&:not(:first-child) {
				padding-top: $pd / 2;
			}

			&:not(:last-child) {
				padding-bottom: $pd / 2;
			}

			&:first-child {
				padding-top: 0 !important;
			}
		}

		.app-link {
			display: inline-block;
			color: $color-accent-pink;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.app-copyright {
		background-color: $color-background-secondary;
		text-align: center;

		.text-sub {
			color: $color-foreground;
		}
	}
}
</style>
