<template>
	<div>
		<section>
			<div v-if="step === 1" class="pd:xl" style="padding-top: 0; text-align: center">
				<div class="logo-text">
					<img :src="require(`@/assets/images/logo-${ appShortName || 'fg' }-text.png`)" :alt="`thumbnail of ${$t('brand_name')}'s logo'`">
				</div>

				<div class="thumbnail">
					<img src="@/assets/images/thumbnail-game_on.png" :alt="`thumbnail of multiple characters from different games`">
				</div>

				<b v-t="'subscription_successful_title'"></b>

				<div class="pd:md" style="padding-left: 0; padding-right: 0; text-align: left">
					<div class="text-sub" style="color: inherit">
						<div v-text="subscriptionDeclaration.title"></div>
						<p></p>
						<div
							v-for="(tnc, indexTnc) in subscriptionDeclaration.tnc"
							:key="indexTnc"
							v-text="tnc">
						</div>
						<p></p>
						<div v-if="contactMailIsShown" v-html="subscriptionDeclaration.contact"></div>
					</div>
				</div>

				<div class="pd:md" style="padding-left: 0; padding-right: 0">
					<a
						v-t="'next'"
						@click="pw ? step++ : $store.dispatch('ui/setModal', { name: 'modal-profile-update-name' })"
						class="app-button pd_horz:md text-trim">
					</a>
				</div>
			</div>

			<div v-if="step === 2" class="pd:xl" style="text-align: center">
				<div class="pd:md" style="padding-left: 0; padding-right: 0">
					<span v-t="{ path: 'subscription_successful_description', args: { brand_name: $t('brand_name') } }"></span>

					<div v-if="pw && isHeaderEnrichmentSupported">
						<p
							v-html="$t('subscription_successful_description_he_password', { password: `<span class='app-link' style='pointer-events: none !important; text-decoration: none !important'>${pw}</span>` })">
						</p>
					</div>

					<div v-else-if="pw">
						<p
							v-html="$t('subscription_successful_description_password', { password: `<span class='app-link' style='pointer-events: none !important; text-decoration: none !important'>${pw}</span>` })">
						</p>
					</div>

					<div v-else-if="otp && isHeaderEnrichmentSupported">
						<p
							v-if="isTelcoOTPLoginSupported"
							v-t="'subscription_successful_description_he_otp'">
						</p>
					</div>

					<div class="pd:md" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
						<a
							v-t="'start'"
							@click="$store.dispatch('ui/setModal', { name: 'modal-profile-update-name' })"
							class="app-button pd_horz:md text-trim">
						</a>
					</div>
				</div>
			</div>

		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'modal-subscribed',
	data () {
		return {
			step: JSON.parse(process.env.VUE_APP_MODAL_SUBSCRIBED_DECLARATION_IS_SHOWN || null) ? 1 : 2,
			pw: this.$store.state.ui.modal.data.pw,
			otp: this.$store.state.ui.modal.data.otp,
			contactMail: process.env.VUE_APP_CONTACT_MAIL,
			contactMailIsShown: JSON.parse(process.env.VUE_APP_CONTACT_MAIL_IS_SHOWN || null),
			sms: {
				unsubscribe: {
					keyword: process.env.VUE_APP_WAP_UNSUBSCRIBE_KEYWORD,
					shortcode: process.env.VUE_APP_WAP_UNSUBSCRIBE_SHORTCODE
				}
			},
			subscriptionDeclaration: {},
			appShortName: process.env.VUE_APP_SHORTNAME,
			isTelcoOTPLoginSupported: JSON.parse(process.env.VUE_APP_OTP_LOGIN_IS_SUPPORTED || null),
			isHeaderEnrichmentSupported: JSON.parse(process.env.VUE_APP_HEADER_ENRICHMENT_IS_SUPPORTED || null)
		}
	},
	computed: {
		...mapState('i18n', {
			localeDefault: 'default'
		})
	},
	created () {
		this.$store.dispatch('ui/setModalWidth', { width: '30em' })
	},
	watch: {
		localeDefault: {
			async handler (localeDefault) {
				// unfinished, package keyword shortcode must get from api based on telco
				const idbLang = (await this.$db.i18n.where({ locale: localeDefault }).toArray())[0]

				this.subscriptionDeclaration = idbLang && idbLang.custom ? idbLang.custom.subscription_declaration : require(`@/assets/translations/${this.$store.state['i18n'].fallback}.json`)['custom_content'].subscription_declaration

				try {
					let subscriptionDeclarationTemp = JSON.stringify(this.subscriptionDeclaration)

					subscriptionDeclarationTemp = subscriptionDeclarationTemp.replace(/{keyword}/g, this.sms.unsubscribe.keyword)
					subscriptionDeclarationTemp = subscriptionDeclarationTemp.replace(/{shortcode}/g, this.sms.unsubscribe.shortcode)
					subscriptionDeclarationTemp = subscriptionDeclarationTemp.replace(/{html_mail}/g, `<a href='mailto: ${this.contactMail}' class='app-link'>${this.contactMail}</a>`)

					this.subscriptionDeclaration = JSON.parse(subscriptionDeclarationTemp)
				} catch (err) {
					console.info('[subscriptionDeclaration] fail to replace text:')
					console.info(err)
				}
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
/deep/ section {
	.logo-text {
		margin: 2em auto;
		width: 100%;
		max-width: 8em;

		img {
			width: 100%;
			height: auto;
		}
	}

	.thumbnail {
		margin: 2em auto;
		width: 100%;
		max-width: 25em;

		img {
			width: 100%;
			height: auto;
		}
	}
}
</style>
