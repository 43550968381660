<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<div class="modal-heading" v-t="'brand_name'"></div>

				<div class="logo">
					<img
						:src="require(`@/assets/images/logo-${ appShortName || 'fg' }-image.png`)"
						:alt="`thumbnail of ${$t('brand_name')}'s logo'`">
				</div>

				<div v-if="notice.heading" v-html="notice.heading" class="separator"></div>
				<div v-if="notice.description" v-html="notice.description" style="white-space: pre-line"></div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'modal-notice',
	data () {
		return {
			isLoading: false,
			notice: {
				heading: '',
				description: '-'
			},
			appShortName: process.env.VUE_APP_SHORTNAME
		}
	},
	methods: {
		updateNotice (payload) {
			if (payload.heading) {
				if (typeof payload.heading === 'object') {
					this.notice.heading = this.$i18n.t(payload.heading.path, payload.heading.args)
				} else {
					this.notice.heading = this.$i18n.t(payload.heading)
				}
			}

			if (payload.description) {
				if (typeof payload.description === 'object') {
					this.notice.description = this.$i18n.t(payload.description.path, payload.description.args)
				} else {
					this.notice.description = this.$i18n.t(payload.description)
				}
			}
		}
	},
	computed: {
		...mapState('ui', {
			modalData: state => state.modal.data
		})
	},
	watch: {
		'modalData': { // eslint-disable-line
			handler (data) {
				if (data) {
					this.updateNotice({
						heading: data.notice.headaing,
						description: data.notice.description
					})
				}
			},
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped>
/deep/ .logo {
	margin: 2em auto;
	width: 100%;
	max-width: 8em;

	img {
		width: 100%;
		height: auto;
	}
}
</style>
