<template>
	<main>
		<div class="app-heading pd:md">
			<div class="app-title" v-t="'account'"></div>
		</div>

		<section>
			<div class="pd_horz:md">
				<div :class="['profile-photo', isLoadingProfileUpdatePhoto ? 'isLoading' : '']">
					<div v-if="isLoadingProfileUpdatePhoto">
						<div class="preloader"></div>
					</div>
					<div class="img_cover">
						<img
							:src="$util.bindUrlCdn($store.getters['users/auth'].thumbnail, 'c300')"
							@error="$event.target.src=require(`@/${$store.getters['ui/getPlaceholder']({ placeholder: 'thumbnailProfileUser' })}`)"
							:alt="`profile image of ${$store.getters['users/auth'].name}`">
					</div>
				</div>
			</div>

			<div class="profile-update-photo pd_horz:md" style="padding-top: 0; padding-bottom: 0">
				<label
					v-t="'profile_edit_photo_title'"
					for="profile-update-photo"
					class="app-link text-sub">
				</label>

				<input
					id="profile-update-photo"
					@change="profileUpdatePhoto"
					type="file"
					accept="image/*"
					style="display: none !important"
					:disabled="isLoadingProfileUpdatePhoto"
					>
			</div>

			<a
				@click="$store.dispatch('ui/setModal', { name: 'modal-profile-update-name', redirect: $route.path })"
				class="profile-update-name f-list pd_horz:md">
				<div v-t="'username'"></div>
				<div class="app-link f-list f-list-right" style="overflow: hidden">
					<div style="overflow: hidden; text-overflow: ellipsis">{{ $store.getters['users/auth'].name }}</div>
					<div>
						<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
						<i class="material-icons" v-else> navigate_next </i>
					</div>
				</div>
			</a>

			<div class="f-list pd_horz:md" v-if="isMsisdnOriginal">
				<div v-t="'phone_number'"></div>
				<div class="f-list-right">
					<div class="text-sub" style="font-size: inherit">{{ $store.getters['users/auth'].msisdn ? $store.getters['users/auth'].msisdn : '-' }}</div>
				</div>
			</div>
		</section>

		<div v-if="false">
			<div class="separator"></div>

			<section>
				<a
					@click="$store.dispatch('ui/setModal', { name: 'modal-profile-update-password', redirect: $route.path })"
					class="app-link f-list pd_horz:md">
					<div v-t="'profile_edit_password_title'"></div>
					<div class="f-list-right">
						<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
						<i class="material-icons" v-else> navigate_next </i>
					</div>
				</a>
			</section>
		</div>

		<div class="separator"></div>
		<section>
			<a
				@click="$store.dispatch('ui/setModal', { name: 'modal-manage-subscription', redirect: $route.path })"
				class="app-link f-list pd_horz:md">
				<div v-t="'manage_subscription'"></div>
				<div class="f-list-right">
					<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
					<i class="material-icons" v-else> navigate_next </i>
				</div>
			</a>
		</section>

		<!--
		<div class="separator"></div>
		<section>
			<div class="f-list pd_horz:md">
				<div v-t="'total_points'"></div>
				<div class="profile-points-info"><i class="material-icons" style="font-size: inherit; margin: 0"> help_outline </i></div>
				<div class="f-list-right">{{ $store.getters['users/auth'].points ? $store.getters['users/auth'].points : 0 }}</div>
			</div>
		</section>

		<div class="separator"></div>
		<section>
			<a class="app-link f-list pd_horz:md">
				<div v-t="'invite_friends'"></div>
				<div class="f-list-right">
					<i class="material-icons" v-if="$store.state.i18n.direction === 'rtl'"> navigate_before </i>
					<i class="material-icons" v-else> navigate_next </i>
				</div>
			</a>
		</section>
		-->

	</main>
</template>

<script>
export default {
	name: 'account',
	inject: ['$util'],
	data () {
		return {
			isLoading: true,
			isLoadingProfileUpdatePhoto: false,
			isMsisdnOriginal: JSON.parse(process.env.VUE_APP_MSISDN_IS_ORIGINAL || null)
		}
	},
	methods: {
		async profileUpdatePhoto (event) {
			this.isLoadingProfileUpdatePhoto = true

			const file = event.target.files[0]

			// [VALIDATE] filesize: must be less than 2mb
			if (file.size > 1024 * 1024 * 2) {
				this.isLoadingProfileUpdatePhoto = false
				this.$store.dispatch('ui/setModal', {
					name: 'modal-notice',
					data: { notice: { description: 'error_image_invalid_file_size' } }
				})

				return
			}

			const reader = new FileReader()
			reader.onload = async _ => {
				try {
					let result = await this.$store.dispatch('api/fetch', {
						method: 'POST',
						contentType: 'multipart/form-data',
						payload: [{
							user: [{
								set_profile_thumbnail: [{
									args: [{
										thumbnail: reader.result.split(',')[1]
									}]
								}]
							}]
						}]
					})

					this.isLoadingProfileUpdatePhoto = false

					result = result.user.set_profile_thumbnail[0][0]

					switch (result.code) {
						case 2001: {
							await this.$store.dispatch('users/update', { uids: this.$store.state.auth.uid })
							break
						}

						case 'E3001': {
							this.$store.dispatch('ui/setModal', {
								name: 'modal-notice',
								data: { notice: { description: 'error_image_invalid_format' } }
							})
							break
						}

						case 'E3002': {
							this.$store.dispatch('ui/setModal', {
								name: 'modal-notice',
								data: { notice: { description: 'error_image_invalid_format' } }
							})
							break
						}

						case 'E3003': {
							this.$store.dispatch('ui/setModal', {
								name: 'modal-notice',
								data: { notice: { description: 'error_image_invalid_file_size' } }
							})
							break
						}

						default: {
							this.$store.dispatch('ui/setModal', {
								name: 'modal-notice',
								data: { notice: { description: 'error_general' } }
							})
							break
						}
					}
				} catch (err) {
					this.$store.dispatch('ui/setModal', {
						name: 'modal-notice',
						data: { notice: { description: 'error_general' } }
					})
				}
			}
			reader.readAsDataURL(file)
		}
	}
}
</script>

<style lang="scss" scoped>
main {
	section {
		background-color: $color-background;
		color: $color-foreground;
		font-weight: 600;

		.f-list-right {
			font-weight: 400;
		}

		> .app-link {
			color: $color-accent-pink;
		}
	}

	.profile-photo {
		position: relative;
		margin: auto;
		height: 5em;
		width: 5em;

		&.isLoading > :not(.img_cover) {
			position: absolute;
			display: flex;
			width: 100%;
			height: 100%;
			background-color: rgba($color-background-invert, .7);
			z-index: 1;

			.preloader {
				margin: auto;
			}
		}
	}

	.profile-update-photo {
		text-align: center;

		.app-link {
			display: inline-block;
			color: $color-accent-turquoise !important;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.profile-update-name {
		.app-link {
			color: $color-accent-pink !important;
		}
	}

	.profile-points-info {
		.material-icons {
			color: $color-accent-pink !important;
		}
	}
}
</style>
