<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<form @submit.prevent="formProfileUpdatePassword" class="app-form">
					<label v-t="'profile_edit_password_title'"></label>
					<input v-model="user.passwordNew" class="text-sub pd:md" type="text" :placeholder="$t('profile_edit_password_placeholder_new')" autofocus required>
					<input v-model="user.passwordNewConfirm" class="text-sub pd:md" type="text" :placeholder="$t('profile_edit_password_placeholder_new_confirm')" required>
					<input v-model="user.password" class="text-sub pd:md" type="text" :placeholder="$t('profile_edit_password_placeholder_current')" required>

					<button id="form-profile-update-password-submit" type="submit" style="display: none !important"></button>
				</form>

				<div class="separator" data-size="md"></div>

				<label
					v-t="'save'"
					@click="isLoading = true"
					for="form-profile-update-password-submit"
					class="app-button pd_horz:md text-trim"
					:disabled="isLoading">
				</label>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'modal-profile-update-password',
	data () {
		return {
			isLoading: false,
			user: {
				password: '',
				passwordNew: '',
				passwordNewConfirm: ''
			}
		}
	},
	methods: {
		async formProfileUpdatePassword () {
			/*
			let result = await this.$store.dispatch('users/update', {
				name: this.user.name
			})

			if (result.code === 2001) {
				// handle redirect
				if (this.$route.path && this.$store.state.ui.modal.redirect !== this.$route.path) {
					this.$router.push({ path: this.$store.state.ui.modal.redirect })
				}

				setTimeout(_ => this.$store.dispatch('ui/setMenu', false))

				this.$store.dispatch('ui/setModal', { name: '', visible: false })
			}
			*/
		}
	},
	mounted () {
		document.querySelector('[autofocus]').focus()
	}
}
</script>
