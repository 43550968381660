<template>
	<main class="pd:xl">
		<div>
			<div class="thumbnail" style="margin-top: 0">
				<img
					src="@/assets/images/thumbnail-404-outline.png"
					:alt="`thumbnail with big bold 404 text`">
			</div>

			<b style="font-size: 4em">404</b>
			<div v-t="'error_404_title'"></div>

			<div class="pd:md" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
				<a
					v-t="'go_back'"
					@click="back"
					class="app-button pd_horz:md text-trim">
				</a>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'notFound',
	data () {
		return {
			routeFrom: '/'
		}
	},
	methods: {
		back () {
			if (!this.routeFrom.name) {
				this.$router.push('/')
			} else {
				this.$router.back()
			}
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.routeFrom = from
		})
	}
}
</script>

<style lang="scss" scoped>
main {
	display: flex;
	background-image: $color-background-gradient-invert;
	color: $color-foreground-invert;
	text-align: center;

	> * {
		margin: auto;
	}

	.thumbnail {
		margin: 2em auto;
		width: 100%;
		max-width: 15em;

		img {
			width: 100%;
			height: auto;
		}
	}
}
</style>
