<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<form @submit.prevent="formForgotPassword" class="app-form">
					<div v-t="'forgot_password'" class="modal-heading"></div>

					<div class="separator" data-size="md"></div>

					<div v-if="!isSuccessful">
						<div v-t="'forgot_password_description'" class="modal-description"></div>

						<div class="separator" data-size="md"></div>

						<label v-t="'phone_number'"></label>
						<input v-model="user.msisdn" class="text-sub pd:md" type="tel" :placeholder="`eg. ${placeholderMsisdn}`" autofocus required>

						<div
							v-if="!form.isPristine && message.error"
							class="error"
							style="margin-bottom: 1em">
							{{ message.error }}
						</div>

						<button id="form-forgot_password-submit" type="submit" style="display: none !important"></button>
					</div>

					<div v-else>
						<div v-t="'forgot_password_successful_description'" class="modal-description"></div>

						<div class="separator" data-size="md"></div>

						<a
							@click="$store.dispatch('ui/setModal', { name: 'modal-login' })"
							v-t="{ path: 'back_to', args: { destination: $t('log_in') } }"
							class="app-button pd_horz:md text-trim">
						</a>
					</div>
				</form>

				<div v-if="!isSuccessful">
					<div class="separator" data-size="md"></div>

					<label
						v-t="'reset_password'"
						for="form-forgot_password-submit"
						class="app-button pd_horz:md text-trim"
						:disabled="isLoading">
					</label>

					<div class="separator" data-size="md"></div>

					<div class="text-sub" style="color: inherit">
						<span v-t="{ path: 'back_to', args: { destination: '' } }"></span>
						<a
							v-t="'log_in'"
							@click="$store.dispatch('ui/setModal', { name: 'modal-login' })"
							class="app-link"
							style="display: inline; vertical-align: initial">
						</a>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'modal-forgot-password',
	data () {
		return {
			isLoading: false,
			user: {
				msisdn: '',
				password: ''
			},
			form: {
				isPristine: true
			},
			message: {
				error: ''
			},
			placeholderMsisdn: process.env.VUE_APP_PLACEHOLDER_MSISDN,
			isSuccessful: false
		}
	},
	methods: {
		async formForgotPassword () {
			this.isLoading = true
			this.form.isPristine = false
			this.message.error = ''

			await setTimeout(_ => { // fake server call
				const result = { // fake result
					code: 2001,
					description: 'bla'
				}

				this.isLoading = false

				switch (result.code) {
					case 2001: {
						this.isSuccessful = true
						break
					}

					case 'E3102': {
						this.message.error = this.$i18n.t('error_login_invalid')
						break
					}

					default: {
						this.message.error = this.$i18n.t('error_general')
						console.warn(result)
					}
				}
			}, 3000)
		}
	}
}
</script>

<style lang="scss" scoped>
.modal-description {
	@extend %text-sub;

	color: inherit;
}

.error {
	@extend %text-sub;
	color: $color-accent-pink;
	text-align: left;

	&:before {
		content: '\2731\0020'
	}
}
</style>
