<template>
	<div :class="['app-sidebar', $store.state.ui.menu.isOpen ? 'app-sidebar-open' : '']">
		<section class="sidebar-header f-list">
			<a @click="$store.dispatch('ui/setMenu', false)" class="f-list-right">
				<i class="material-icons">close</i>
			</a>
		</section>

		<section>
			<div v-if="$store.state.auth.uid === ''">
				<a
					v-t="$store.state.ui.isWifi ? 'log_in' : 'subscribe'"
					@click="$store.dispatch('ui/setMenu', false); $store.dispatch('auth/prompt', { useLogin: true })"
					class="pd_horz:md"
					style="display: block">
				</a>
			</div>

			<div v-if="$store.state.auth.uid">
				<div style="margin: auto; height: 5em; width: 5em">
					<div class="img_cover">
						<img
							:src="$util.bindUrlCdn($store.getters['users/auth'].thumbnail, 'c300')"
							@error="$event.target.src=require(`@/${$store.getters['ui/getPlaceholder']({ placeholder: 'thumbnailProfileUser' })}`)"
							:alt="`profile image of ${$store.getters['users/auth'].name}`">
					</div>
				</div>
				<div class="pd_horz:md" style="font-weight: 600; overflow: hidden; text-overflow: ellipsis" v-text="$store.getters['users/auth'].name"></div>

			</div>
		</section>

		<div class="pd_horz:md"><hr></div>

		<section>
			<nav>
				<router-link to="/" tag="a" @click.native="$store.dispatch('ui/setMenu', false)" class="pd_horz:md">
					<div v-t="'home'"></div>
				</router-link>
				<router-link to="/genre" tag="a" @click.native="$store.dispatch('ui/setMenu', false)" class="pd_horz:md">
					<div v-t="'all_games'"></div>
				</router-link>
				<router-link
					v-if="$store.state.auth.uid && isFeatureReleased"
					to="/favourites"
					tag="a"
					@click.native="$store.dispatch('ui/setMenu', false)"
					class="pd_horz:md">
					<div v-t="'favourites'"></div>
				</router-link>
				<router-link
					v-if="$store.state.auth.uid"
					to="/account"
					tag="a"
					@click.native="$store.dispatch('ui/setMenu', false)" class="pd_horz:md">
					<div v-t="'account'"></div>
				</router-link>
				<router-link
					v-if="$store.state.auth.uid && isFeatureReleased"
					to="/history"
					tag="a"
					@click.native="$store.dispatch('ui/setMenu', false)" class="pd_horz:md">
					<div v-t="'history'"></div>
				</router-link>

				<a
					v-if="$store.state.auth.uid && $store.state.ui.isWifi"
					@click="$store.dispatch('auth/logout'); $store.dispatch('ui/setMenu', false)"
					class="pd_horz:md">
					<div v-t="'log_out'"></div>
				</a>
			</nav>
		</section>
	</div>
</template>

<script>
export default {
	name: 'TheSidebar',
	inject: ['$util'],
	data () {
		return {
			isFeatureReleased: false
		}
	},
	created () {
		/*
		this.$router.beforeEach((to, from, next) => {
			this.$store.dispatch('ui/setMenu', false)
			next()
		})
		*/

		this.isFeatureReleased = process.env.NODE_ENV === 'development'
	}
}
</script>

<style lang="scss" scoped>
.app-sidebar {
	background-image: $color-background-gradient-invert;
	color: $color-foreground-invert;

	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
}

.sidebar-header {
	height: 4.5em;

	> * {
		padding: 1.75em 1em;
		line-height: 1;
	}

	> *:first-child {
		padding-left: 1.75em;
	}
	> *:last-child {
		padding-right: 1.75em
	}
}

nav {
	a {
		display: block;
		color: $color-accent-turquoise !important;
	}

	@for $i from 1 through 4 {
	& > *:nth-of-type(#{$i}) {
		color: mix(#2AF598, #0FBED8, 100%/$i) !important;
	}
}
}

hr {
	margin: 0 40%;
	border-top: .375em solid;
	border-color: $color-accent-pink;
}
</style>
