<template>
	<div>
		<section>
			<div class="f-list pd:lg">
				<div class="f-list-right">
					<a @click="$store.dispatch('ui/setModal', { name: '', visible: false })">
						<i class="material-icons"> close </i>
					</a>
				</div>
			</div>

			<div class="pd:xl" style="padding-top: 0; text-align: center">
				<form class="app-form">
					<label v-t="'manage_subscription'"></label>

					<div class="pd:md" style="padding-left: 0; padding-right: 0; padding-bottom: 0">
						<div class="text-sub" style="color: inherit">
							<div v-if="(['A', 'S']).includes($store.state.auth.status)">
								<div v-if="packageHasMultiple || !(charging.price && charging.period)" v-t="{ path: 'manage_subscription_subscribed', args: {
									brand_name: $t('brand_name')
								} }">
								</div>
								<div v-else v-t="{ path: 'manage_subscription_subscribed_with_package', args: {
									brand_name: $t('brand_name'),
									price: charging.price,
									period: charging.period
								} }">
								</div>

								<div
									v-if="!isTelcoWapUnsubscribeSupported"
									class="pd:md"
									style="padding-left: 0; padding-right: 0; padding-bottom: 0">
									<div v-t="{ path: 'manage_subscription_subscribed_p2_wap', args: {
										keyword: sms.unsubscribe.keyword,
										shortcode: sms.unsubscribe.shortcode
								} }"></div>
								</div>
							</div>
							<div v-else>
								<div v-t="{ path: 'manage_subscription_not_subscribed', args: { brand_name: $t('brand_name') } }">
								</div>

								<div
									v-if="!packageHasMultiple && charging.price && charging.period"
									v-t="{ path: 'manage_subscription_not_subscribed_description', args: {
									price: charging.price,
									period: charging.period
								} }">
								</div>
							</div>
						</div>
					</div>
				</form>

				<div class="separator" data-size="md"></div>

				<label
					v-if="isTelcoWapUnsubscribeSupported"
					@click="isLoading = true; manageSubscription()"
					class="app-button pd_horz:md text-trim"
					:disabled="isLoading">
					<span v-if="(['A', 'S']).includes($store.state.auth.status)" v-t="'unsubscribe'"></span>
					<span v-else v-t="'subscribe'"></span>
				</label>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'modal-manage-subscription',
	data () {
		return {
			isLoading: false,
			packageHasMultiple: JSON.parse(process.env.VUE_APP_SUBSCRIPTION_PACKAGE_HAS_MULTIPLE || null),
			charging: {
				price: process.env.VUE_APP_SUBSCRIPTION_PACKAGE_PRICE, // unfinished: fetch from server
				period: process.env.VUE_APP_SUBSCRIPTION_PACKAGE_OCCURENCE // unfinished: fetch from server
			},
			isTelcoWapUnsubscribeSupported: JSON.parse(process.env.VUE_APP_WAP_UNSUBSCRIBE_IS_SUPPORTED || null),
			sms: {
				unsubscribe: {
					keyword: process.env.VUE_APP_WAP_UNSUBSCRIBE_KEYWORD,
					shortcode: process.env.VUE_APP_WAP_UNSUBSCRIBE_SHORTCODE
				}
			}
		}
	},
	methods: {
		async manageSubscription () {
			switch (this.$store.state.auth.status) {
				case 'A': {
					window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_UNSUB}`
					break
				}

				case 'I': {
					window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_SUB}`
					break
				}

				case 'S': {
					window.location.href = `${process.env.VUE_APP_URL_PORTAL}/${process.env.VUE_APP_URL_PARAMS_PORTAL_UNSUB}`
					break
				}
			}
		}
	}
}
</script>
